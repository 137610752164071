import { createAction } from "@reduxjs/toolkit";
import Reading from "spectral-core/models/Reading";

interface SetReadingsPayload {
  readings: Reading[];
  fetchedAtTimestamp: number;
}

export const setReadingsAction =
  createAction<SetReadingsPayload>("SET_READING");
export const setReadingsLogoutAction = createAction("LOGOUT");
