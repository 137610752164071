import React from "react";
import intl from "react-intl-universal";
import Box2 from "components/PricingBox/Box2.js";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const PricingBox = () => {
  const locales = {
    "pt-BR": require("../../locales/pt-BR.json"),
    "en-US": require("../../locales/en-US.json")
  };

  const currentLocale = locales[navigator.language]
    ? navigator.language
    : "en-US";

  intl.init({
    currentLocale,
    locales
  });

  const featureBox1 = [
    intl.get("plans.feature_1"),
    intl.get("plans.feature_2"),
    intl.get("plans.feature_3"),
    intl.get("plans.feature_4"),
    intl.get("plans.feature_5")
  ];
  const featureBox2 = [
    intl.get("plans.feature_6"),
    intl.get("plans.feature_2"),
    intl.get("plans.feature_3"),
    intl.get("plans.feature_4"),
    intl.get("plans.feature_5")
  ];
  const featureBox3 = [
    intl.get("plans.feature_7"),
    intl.get("plans.feature_2"),
    intl.get("plans.feature_3"),
    intl.get("plans.feature_4"),
    intl.get("plans.feature_5")
  ];
  return (
    <div className="card-deck mb-3 text-center">
      <Grid container>
        <Box boxShadow={2} m={1} p={1} bgcolor="white">
          <Box2
            price=""
            title="Membership bronze"
            btnClass="btn-outline-primary"
            btnTitle={intl.get("plans.btn_buy")}
            link="https://buy.stripe.com/test_aEU5oj4tC1Mt252cMO"
            feature={featureBox1}
          />
        </Box>

        <Box boxShadow={2} m={1} p={1} bgcolor="white">
          <Box2
            feature={featureBox2}
            price=""
            title="Membership silver"
            btnClass="btn-primary"
            btnTitle={intl.get("plans.btn_buy")}
            link="https://buy.stripe.com/test_4gw183gck2QxcJG001"
          />
        </Box>

        <Box boxShadow={2} m={1} p={1} bgcolor="white">
          <Box2
            feature={featureBox3}
            price=""
            title="Membership gold"
            btnClass="btn-primary"
            btnTitle={intl.get("plans.btn_buy")}
            link="https://buy.stripe.com/test_3cs2c7d081Mt10YdQQ"
          />
        </Box>
      </Grid>
    </div>
  );
};

export default PricingBox;
