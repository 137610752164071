import { combineReducers } from "redux";
import userReducer from "./user/user.reducer";
import readingsReducer from "./readings/readings.reducer";
import routesReducer from "./routes/routes.reducer";
import applicationsReducer from "./applications/applications.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  reading: readingsReducer,
  routes: routesReducer,
  applications: applicationsReducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;

export default rootReducer;
