import { createAction } from "@reduxjs/toolkit";
import User from "spectral-core/models/User";

// redux-persist can't handle Date objects, so we need to a unix timestamp instead
export interface TokenWithTimeStamp {
  accessToken: string;
  expiresAtTimestamp: number;
}

interface UserWithToken {
  user: User;
  token: TokenWithTimeStamp;
}

export const loginAction = createAction<UserWithToken>("SET_CURRENT_USER");
export const logoutAction = createAction("USER_LOGOUT");
