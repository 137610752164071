import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "assets/img/Logo-Spectral-Solutions-200-2-01-01.png";

const useStyles = makeStyles(theme => ({
  header: {
    background: "#333333",
    width: "842px",
    height: "70px",
    marginTop: "0",
    position: "relative",
    zIndex: "99"
  },
  title: {
    textAlign: "center"
  },
  logo: {
    position: "relative",
    padding: "5px 5px",
    zIndex: "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      height: "1px",
      right: "15px",
      width: "80%"
    }
  },
  img: {
    left: "15px",
    width: "130px",
    top: "5px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0"
  }
}));

export default function Header() {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className={classes.logo}>
        <img src={logo} alt="logo" className={classes.img} />
      </div>
    </div>
  );
}
