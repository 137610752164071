import React, { useState, useCallback, useMemo } from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Tables/StatTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { StatHeaders, convertDataIntoTableData } from "../../utils/table.js";

//@ts-ignore
import lodash from "lodash";

import {
  Map,
  TileLayer,
  CircleMarker,
  Tooltip,
  //@ts-ignore
} from "react-leaflet";
import SpinLoading from "components/Loading/SpinLoading.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import intl from "react-intl-universal";

import { useRootSelector } from "hooks/reduxHooks";
import Reading from "spectral-core/models/Reading";
import { useUserLoggedIn } from "hooks/userHooks";
import { useGetReadings } from "hooks/readingsHooks";

//@ts-ignore
const useStyles = makeStyles(styles);

//@ts-ignore
function convertPredictedDataIntoPinData(predictionList) {
  predictionList = JSON.parse(predictionList);
  //@ts-ignore
  let dataConverted = predictionList.map((predictions) => [
    " " + predictions.ParameterName + " ",
    predictions.Value.toFixed(2),
  ]);

  return dataConverted;
}

function createCoodenateArray(foundRow: Reading[]) {
  //@ts-ignore
  let coordenates = lodash.map(foundRow, (item) => {
    try {
      const name = item.name;
      let position = ["0", "0"];
      if (item.geoLocation != null && item.geoLocation.length > 10) {
        const { Latitude, Longitude } = JSON.parse(item.geoLocation);
        position = [Latitude, Longitude];
      }

      var predValues = convertPredictedDataIntoPinData(item.predictedValues);

      return { name, position, predValues };
    } catch (error) {}
  });

  return lodash.compact(coordenates);
}

export default function Coordinates() {
  const classes = useStyles();

  const [selectedReadings, setSelectedReadings] = useState<Reading[]>([]);

  const isLoggedIn = useUserLoggedIn();

  useGetReadings();

  const readings = useRootSelector((state) => state.reading.readings);

  const loading = !isLoggedIn || readings === undefined;

  const pins = useMemo(
    () => createCoodenateArray(selectedReadings),
    [selectedReadings]
  );

  const tableData = useMemo(
    () => (readings !== undefined ? convertDataIntoTableData(readings) : []),
    [readings]
  );

  const handleSelectionCallback = useCallback(
    (rows: any[]) => {
      if (readings === undefined) return;
      const selected = rows.map((row) => readings[row.tableData.index]);
      setSelectedReadings(selected);
    },
    [readings]
  );

  const table = useMemo(() => {
    return loading ? (
      <SpinLoading />
    ) : (
      <Table
        headers={StatHeaders}
        tableData={tableData}
        SelectionChange={handleSelectionCallback}
      />
    );
  }, [loading, tableData, handleSelectionCallback]);

  return (
    <div>
      <GridContainer>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                {intl.get("coordinate.reads")}
              </h4>
            </CardHeader>
            <CardBody>{table}</CardBody>
          </Card>
        </GridItem>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                {intl.get("coordinate.coordinate")}
              </h4>
            </CardHeader>
            {loading ? (
              <SpinLoading />
            ) : (
              <div
                style={{
                  marginBottom: "15px",
                  marginLeft: "15px",
                  marginTop: "10px",
                }}
              >
                {pins ? (
                  <Map
                    //@ts-ignore
                    center={pins[0] ? pins[0].position : [0, 0]}
                    zoom={13}
                    style={{ height: "60vh" }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {/*@ts-ignore*/}
                    {pins.map((pin, index) => (
                      <CircleMarker
                        key={index}
                        center={pin.position}
                        fillOpacity={0.5}
                        stroke={true}
                        radius={2}
                      >
                        <Tooltip direction="top" offset={[-8, -2]} opacity={1}>
                          <span>{pin.name}</span>
                          <span>{"\n"}</span>
                          <span>{pin.predValues}</span>
                        </Tooltip>
                      </CircleMarker>
                    ))}
                  </Map>
                ) : (
                  <Map center={[0, 0]} zoom={13} style={{ height: "60vh" }}>
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                  </Map>
                )}
              </div>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
