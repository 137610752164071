import React, { useState, useMemo, useCallback } from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Tables/StatTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { StatHeaders, convertDataIntoTableData } from "../../utils/table.js";
import StatisticsReport from "components/StatisticsReport/StatisticsReport";

//@ts-ignore
import lodash from "lodash";

import { ChartData } from "types/chartData";

import StatisticsHandler from "../../statisticsModels/StatisticsHandler.js";
import SpinLoading from "components/Loading/SpinLoading.js";
import intl from "react-intl-universal";

import Reading from "spectral-core/models/Reading";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useRootSelector } from "hooks/reduxHooks";

import { useUserLoggedIn } from "hooks/userHooks";
import { useGetReadings } from "hooks/readingsHooks";
import Chart from "components/Chart/Chart";

//@ts-ignore
const useStyles = makeStyles(styles);

function createChartData(readings: Reading[]) {
  if (readings.length === 0) {
    return undefined;
  }

  //@ts-ignore
  let predicteds = lodash.map(readings, (item) => {
    return item != null && item.predictedValues != null
      ? JSON.parse(item.predictedValues)
      : [];
  });

  predicteds = lodash.flattenDeep(
    //@ts-ignore
    predicteds.filter((predicted) => predicted != null)
  );

  if (predicteds.length === 0) return undefined;

  const statisticsHandler = new StatisticsHandler(predicteds);
  return statisticsHandler.createAnalysis() as ChartData;
}

//@ts-ignore
export default function Statistics() {
  const classes = useStyles();

  const [selectedReadings, setSelectedReadings] = useState<Reading[]>([]);

  const isLoggedIn = useUserLoggedIn();

  useGetReadings();

  const readings = useRootSelector((state) => state.reading.readings);

  const chartData = useMemo(
    () => createChartData(selectedReadings),
    [selectedReadings]
  );

  const loading = !isLoggedIn || readings === undefined;
  const tableData = useMemo(
    () => (readings !== undefined ? convertDataIntoTableData(readings) : []),
    [readings]
  );

  const handleSelectionCallback = useCallback(
    (rows: any[]) => {
      if (readings === undefined) return;
      const selected = rows.map((row) => readings[row.tableData.index]);
      setSelectedReadings(selected);
    },
    [readings]
  );

  const table = useMemo(() => {
    return loading ? (
      <SpinLoading />
    ) : (
      <Table
        headers={StatHeaders}
        tableData={tableData}
        SelectionChange={handleSelectionCallback}
      />
    );
  }, [loading, handleSelectionCallback, tableData]);

  return (
    <div>
      <StatisticsReport
        selectedReadings={selectedReadings}
        statisticsData={chartData}
      />
      <GridContainer>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                {intl.get("statistics.reads")}
              </h4>
            </CardHeader>
            <CardBody>{table}</CardBody>
          </Card>
        </GridItem>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={6}>
          <Chart
            title={intl.get("statistics.min")}
            summaryStat={chartData?.[0]}
          />
        </GridItem>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={6}>
          <Chart
            title={intl.get("statistics.max")}
            summaryStat={chartData?.[1]}
          />
        </GridItem>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={6}>
          <Chart
            title={intl.get("statistics.mean")}
            summaryStat={chartData?.[2]}
          />
        </GridItem>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={6}>
          <Chart
            title={intl.get("statistics.deviation")}
            summaryStat={chartData?.[3]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
