import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import VictoryBarChart from "components/VictoryCharts/VictoryBarChart.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { SummaryStat } from "types/chartData";

//@ts-ignore
const useStyles = makeStyles(styles);

export interface ChartProps {
  title: string;
  summaryStat: SummaryStat | undefined;
}

export default function Chart({ title, summaryStat }: ChartProps) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>{title}</h4>
      </CardHeader>
      <VictoryBarChart
        chartHeight={250}
        chartWidth={500}
        chartPadding={{ left: 50, top: 35, bottom: 35, right: 20 }}
        dependentAxisWidth={400}
        dependentAxisHeight={400}
        dependentAxisStroke={"#756f6a"}
        dependentAxisfontSize={10}
        dependentAxisPadding={40}
        xAxisWidth={400}
        xAxisHeight={400}
        xAxisAngle={20}
        xAxisStroke={"#756f6a"}
        xAxisfontSize={10}
        xAxisPadding={40}
        barPosition={"bottom"}
        barFlyoutStyleStroke={"#02adc2"}
        barFill={"#02adc2"}
        chartData={summaryStat ? summaryStat : null}
      />
    </Card>
  );
}
