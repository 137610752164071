import React from "react";
import intl from "react-intl-universal";
// react plugin for creating charts
// @material-ui/core
// core components
import CardReleases from "components/CardReleases/CardReleases.js";
import "components/Map/map-style.css";
import Grid from "@material-ui/core/Grid";

const Releases = (props) => {
  return (
    <div>
      <div>
        <Grid container>
          <CardReleases
            cardTitle="iSpectral Android"
            cardBody={intl.get("softwares.ispectral_description")}
            cardDownloadUrl="https://play.google.com/store/apps/details?id=br.com.spectralsolutions.SciRm"
            cardBottomVersion=""
            cardBottomVersionDate="Release"
          ></CardReleases>
          <CardReleases
            cardTitle="iSpectral iOS"
            cardBody={intl.get("softwares.ispectral_description")}
            cardDownloadUrl="https://apps.apple.com/us/app/ispectral/id1561047468"
            cardBottomVersion=""
            cardBottomVersionDate="Release"
          ></CardReleases>
        </Grid>
      </div>
    </div>
  );
};

export default Releases;
