import React from "react";
import Box from "@material-ui/core/Box";
import XLSX from "xlsx";
//@ts-ignore
import { saveAs } from "file-saver";
import {
  fillHeader,
  fillDataInExcel,
  createStatisticsSheet,
} from "utils/reportHandler";
import "../../assets/css/report-style.css";
import intl from "react-intl-universal";
import Reading from "spectral-core/models/Reading";
import { ChartData } from "types/chartData";

interface StatisticsReportProps {
  selectedReadings: Reading[];
  statisticsData: ChartData | undefined;
}

//@ts-ignore
export default function StatisticsReport(props: StatisticsReportProps) {
  function buildReportSheet() {
    if (
      props.selectedReadings.length === 0 ||
      props.statisticsData === undefined
    )
      return;

    let header = [];
    let wb = XLSX.utils.book_new();

    wb.Props = {
      Title: "Relatório",
      Subject: "",
      Author: "Spectral Solutions",
      CreatedDate: new Date(),
    };

    wb.SheetNames.push("Amostras");
    let filledData = fillDataInExcel(
      props.selectedReadings,
      props.statisticsData
    );
    header = fillHeader(props.selectedReadings, props.statisticsData);

    let ws_data = [header];

    filledData.forEach((element) => {
      //@ts-ignore
      ws_data.push(element);
    });

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Amostras"] = ws;
    createStatisticsSheet(wb, props.statisticsData);
    return XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  }

  //@ts-ignore
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  function downloadXlsx(event: React.MouseEvent) {
    event.preventDefault();

    const xlsx = buildReportSheet();
    if (xlsx === undefined) return;

    saveAs(
      new Blob([s2ab(xlsx)], { type: "application/octet-stream" }),
      "Analises.xlsx"
    );
  }

  return (
    <div>
      <Box
        display={{
          xs: "none",
          md: "block",
          xl: "block",
          sm: "none",
          lg: "block",
        }}
      >
        <button onClick={downloadXlsx} className="reportButtom">
          {intl.get("btn.generateReport")}
        </button>
      </Box>
    </div>
  );
}
