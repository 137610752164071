import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Tables/DashTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DashHeaders, convertDataIntoTableData } from "../../utils/table.js";
import Greeting from "components/Greeting/Greeting";
import Report from "components/Report/Report";
//@ts-ignore
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import "components/Map/map-style.css";
import SpinLoading from "components/Loading/SpinLoading.js";
import intl from "react-intl-universal";
import { defaultScale, CreateChart, CreateBarChart } from "utils/charts.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useRootSelector } from "hooks/reduxHooks";
import Reading from "spectral-core/models/Reading";
import { useUserLoggedIn } from "hooks/userHooks";
import { useGetReadings } from "hooks/readingsHooks";

//@ts-ignore
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [columnData, setColumnData] = useState([]);
  const [selected, setSelected] = useState<Reading>();
  const [chartData, setChartData] = useState(defaultScale);
  const [barChartData, setBarChartData] = useState(defaultScale);
  const [curretPosition, setCurretPosition] = useState([0, 0]);
  const [zoom, setZoom] = useState(13);
  const [loading, setLoading] = useState(false);

  const isLoggedIn = useUserLoggedIn();

  useGetReadings();

  const user = useRootSelector((state) => state.user.currentUser);
  const readings = useRootSelector((state) => state.reading.readings);

  useEffect(() => {
    setLoading(true);

    if (!isLoggedIn) return;

    if (readings === undefined) return;

    setLoading(false);

    if (readings.length === 0) return;

    setColumnData(convertDataIntoTableData(readings));

    fillParameterValues(readings[0].predictedValues);
    fillChart(readings[0]);

    setSelected(readings[0]);
  }, [isLoggedIn, readings]);

  const handleClick = (event: React.MouseEvent, prop: any) => {
    event.preventDefault();

    try {
      const foundRead = readings?.find(
        (read: Reading) =>
          read.userName === prop[1] && read.dateTime === prop[6]
      );

      if (foundRead != null) {
        if (foundRead.predictedValues.includes("-") === true) {
          fillMap(foundRead.geoLocation);
          fillChart(foundRead);
          setSelected(foundRead);
        } else {
          fillMap(foundRead.geoLocation);
          fillParameterValues(foundRead.predictedValues);
          fillChart(foundRead);
          setSelected(foundRead);
        }
      }
    } catch (e) {
      setCurretPosition([0, 0]);
      setZoom(13);
    }
  };

  const fillParameterValues = (apiParameters: string) => {
    var selectedParameter = JSON.parse(apiParameters);

    setBarChartData(
      //@ts-ignore
      CreateBarChart(
        //@ts-ignore
        selectedParameter.map((name) => name.ParameterName),
        //@ts-ignore
        selectedParameter.map((item) => item.Value.toFixed(4))
      )
    );
  };

  //@ts-ignore
  const fillMap = (apiLocation) => {
    try {
      var parsedlocation = JSON.parse(apiLocation);

      setCurretPosition([parsedlocation.Latitude, parsedlocation.Longitude]);
      setZoom(13);
    } catch (e) {
      setCurretPosition([0, 0]);
      setZoom(13);
    }
  };

  function fillChart(apiChartValues: Reading) {
    setChartData(
      CreateChart(
        JSON.parse(apiChartValues.wavelenght),
        JSON.parse(apiChartValues.signal)
      )
    );
  }

  return (
    <div>
      {user && <Greeting user={user} />}
      {selected && user && <Report selectedReading={selected} user={user} />}
      <GridContainer>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={6}>
          {/*@ts-ignore*/}
          <Card chart style={{ height: "320px" }}>
            {/*@ts-ignore*/}
            <CardHeader color="primary" style={{ height: "250px" }}>
              <ChartistGraph
                style={{ height: "250px" }}
                className="ct-chart"
                // data={chartData ? chartData.data : null}
                data={chartData.data}
                type="Line"
                options={chartData.options}
                // listener={chartData.animation}
              />
            </CardHeader>
            <p
              className={classes.cardCategory}
              style={{
                height: "270px",
                marginTop: "20px",
                fontSize: "22px",
                display: "flex",
                marginLeft: "10px",
              }}
            >
              {intl.get("home.signal")}
            </p>
          </Card>
        </GridItem>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={6}>
          {/*@ts-ignore*/}
          <Card chart style={{ height: "320px" }}>
            {/*@ts-ignore*/}
            <CardHeader color="primary" style={{ height: "250px" }}>
              <ChartistGraph
                style={{ height: "250px" }}
                className="ct-bar"
                // data={barChartData ? barChartData.data : null}
                data={barChartData.data}
                type="Bar"
                options={barChartData.options}
                // listener={barChartData.animation}
              />
            </CardHeader>
            <p
              className={classes.cardCategory}
              style={{
                height: "270px",
                marginTop: "20px",
                fontSize: "22px",
                display: "flex",
                marginLeft: "10px",
              }}
            >
              {intl.get("home.parameters")}
            </p>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                {intl.get("home.coordinate")}
              </h4>
            </CardHeader>
            {loading ? (
              <SpinLoading />
            ) : (
              <div
                style={{
                  marginBottom: "15px",
                  marginLeft: "15px",
                  marginTop: "10px",
                }}
              >
                <Map
                  center={curretPosition}
                  zoom={zoom}
                  style={{ height: "50vh" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={curretPosition}>
                    <Popup>{selected?.name}</Popup>
                  </Marker>
                </Map>
              </div>
            )}
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                {intl.get("home.reads")}
              </h4>
            </CardHeader>

            <CardBody>
              {loading ? (
                <SpinLoading />
              ) : (
                <Table
                  headers={DashHeaders}
                  tableData={columnData}
                  tableProps={{
                    onRowClick: handleClick,
                  }}
                ></Table>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
