import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Logo from "../../assets/img/logo.svg";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    width: "24rem",
    height: "10rem",
  },
  box: {
    width: "24rem",
    height: "10rem",
  },
  contentContainer: {
    marginTop: "0.5rem",
    display: "block",
    height: "8rem",
  },
  contentBody: {
    display: "table-row",
    height: "8rem",
  },
  imageWrapper: {
    display: "table-cell",
    height: "8rem",
    verticalAlign: "top",
  },
  logo: {
    width: "4rem",
    height: "4rem",
  },
  textBodyContainer: {
    display: "table-cell",
    height: "8rem",
    width: "100%",
  },
  footerContainer: {
    display: "flex",
    height: "2rem",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "solid 1px rgb(128, 128, 128, 0.25)",
  },
}));

export default function CardReleases(props) {
  const classes = useStyles();
  const [cardTitle] = useState(props.cardTitle);
  const [cardBody] = useState(props.cardBody);
  const [cardDownloadUrl] = useState(props.cardDownloadUrl);
  const [cardBottomVersion] = useState(props.cardBottomVersion);
  const [cardBottomVersionDate] = useState(props.cardBottomVersionDate);

  return (
    <div style={{ marginBottom: "1rem" }}>
      <Box boxShadow={2} m={1} p={1} bgcolor="white" className={classes.box}>
        <div className={classes.contentContainer}>
          <div className={classes.contentBody}>
            <div className={classes.imageWrapper}>
              <img src={Logo} className={classes.logo} />
            </div>
            <div className={classes.textBodyContainer}>
              <div id="title" style={{ height: "1.5rem" }}>
                <h2
                  style={{
                    height: "90%",
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "left",
                    margin: "0",
                    marginLeft: "2rem",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href="#"
                  >
                    {cardTitle}
                  </a>
                </h2>
              </div>
              <div
                id="resume"
                style={{
                  height: "68%",
                  display: "inline-block",
                  marginLeft: "2rem",
                  marginRight: "0.5rem",
                  fontSize: "14px",
                  textOverflow: "ellipsis",
                  textAlign: "justify",
                }}
              >
                <div
                  style={{
                    margin: "0",
                    height: "65%",
                    textOverflow: "ellipsis",
                    webkitLineClamp: "3",
                    webkitBoxOrient: "vertical",
                    lineHeight: "1.2rem",
                    display: "-webkit-box",
                    overflow: "hidden",
                  }}
                >
                  {cardBody}
                </div>
                <div
                  style={{
                    marginRight: "0.5rem",
                    fontSize: "14px",
                    marginTop: "0.4rem",
                  }}
                >
                  <a
                    style={{
                      fontWeight: "bold",
                      textDecoration: "none",
                      color: "inherit",
                    }}
                    href={cardDownloadUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footerContainer}>
          <div>{cardBottomVersion}</div>
          <div>{cardBottomVersionDate}</div>
        </div>
      </Box>
    </div>
  );
}
