import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import getRoutes from "routes";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/Logo-Spectral-Solutions-200-2-01-01.png";

import { useRootSelector } from "hooks/reduxHooks";

//@ts-ignore
const useStyles = makeStyles(styles);

export default function Admin() {
  const userState = useRootSelector((state) => state.user);

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image] = React.useState(bgImage);
  const [color] = React.useState("blue");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const adminRoutes =
    userState.currentUser !== undefined
      ? getRoutes(userState.currentUser?.accessLevel).filter(
          (x) => x.layout === "/admin"
        )
      : [];

  const switchRoutes = (
    <Switch>
      {adminRoutes.map((prop, key) => {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      })}
      {userState.currentToken?.accessToken ? (
        <Redirect to="/admin/dashboard" />
      ) : (
        <Redirect to="/" />
      )}
    </Switch>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    let ps: PerfectScrollbar | undefined;
    if (navigator.platform.indexOf("Win") > -1) {
      //@ts-ignore
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        if (ps) {
          ps.destroy();
        }
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={adminRoutes}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        //@ts-ignore
        color={color}
        {...userState}
      />
      {/*@ts-ignore*/}
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={adminRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...userState}
        />

        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
