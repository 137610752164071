import React from "react";
import { CircularProgress } from "@material-ui/core";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00acc1",
    },
  },
});

export default function SpinLoading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100px",
        alignItems: "center",
      }}
    >
      <MuiThemeProvider theme={theme}>
        <CircularProgress size={60} color={"primary"} />
      </MuiThemeProvider>
    </div>
  );
}
