import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer, { RootReducer } from "./root-reducer";

const persistConfig = {
  key: "root",
  storage: storage,
  debug: false,
  blacklist: ["system", "chartData", "auth", "locale", "reading"],
  whitelist: ["user"],
};

const persistedReducer = persistReducer<RootReducer>(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type RootStore = typeof store;

export const persistor = persistStore(store);
