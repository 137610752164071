import React from "react";

import {
  VictoryBar,
  VictoryTooltip,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel,
  VictoryContainer,
} from "victory";

// Styles sets a 500 max-width on the root container div for the app.
import "../VictoryCharts/barChart-style.css";

function VictoryBarChart(props) {
  return (
    <div className="Bar">
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={{ x: 50 }}
        responsive={true}
        animate={{
          duration: 500,
          onLoad: { duration: 200 },
        }}
        height={props.chartHeight}
        width={props.chartWidth}
        padding={props.chartPadding}
        containerComponent={<VictoryContainer responsive={true} />}
      >
        <VictoryAxis
          dependentAxis
          label={props.dependentAxisLabelText}
          width={props.dependentAxisWidth}
          height={props.dependentAxisHeight}
          tickLabelComponent={<VictoryLabel width={100} fontSize={10} />}
          style={{
            axis: { stroke: props.dependentAxisStroke },
            axisLabel: {
              fontSize: props.dependentAxisfontSize,
              padding: props.dependentAxisPadding,
            },
          }}
        />
        <VictoryAxis
          label={props.xAxisLabelText ? props.xAxisLabelText : ""}
          width={props.xAxisWidth}
          height={props.xAxisHeight}
          tickLabelComponent={
            <VictoryLabel angle={props.xAxisAngle} fontSize={10} />
          }
          style={{
            axis: { stroke: props.xAxisStroke },
            axisLabel: {
              fontSize: props.xAxisfontSize,
              padding: props.xAxisPadding,
            },
          }}
        />
        <VictoryBar
          labels={({ datum }) => datum.y}
          labelComponent={
            <VictoryTooltip
              pointerOrientation={props.barPosition}
              cornerRadius={0}
              flyoutStyle={{
                stroke: props.barFlyoutStyleStroke,
                strokeWidth: 2,
              }}
            />
          }
          style={{ data: { fill: props.barFill } }}
          data={props.chartData ? props.chartData.data : []}
        />
      </VictoryChart>
    </div>
  );
}

export default VictoryBarChart;
