import LoginDTO from "./dtos/LoginDTO";
import UserWithTokenDTO from "./dtos/UserWithTokenDTO";
import User from "./models/User";

import axios from "axios";
import Reading from "./models/Reading";
import SpectralModel from "./models/SpectralModel";
import UserCreateDTO from "./dtos/UserCreateDTO";
import UserUpdateDTO from "./dtos/UserUpdateDTO";
import NewCompanyFirstUserCreateDTO from "./dtos/NewCompanyFirstUserCreateDTO";
import PasswordChangeDTO from "./dtos/PasswordChangeDTO";

const baseUrl = process.env.REACT_APP_SPECTRAL_CORE_API_V2;

export async function loginUser(login: LoginDTO): Promise<UserWithTokenDTO> {
  const res = await axios.post(`${baseUrl}users/auth`, login);
  const userWithToken: UserWithTokenDTO = res.data;
  if (typeof userWithToken.token.expiresAt === "string") {
    let date = new Date();
    date.setTime(Date.parse(userWithToken.token.expiresAt));
    userWithToken.token.expiresAt = date;
  }
  return res.data;
}

export async function createNewCompanyFirstUser(
  newUser: NewCompanyFirstUserCreateDTO
): Promise<User> {
  const res = await axios.post(`${baseUrl}users/newCompany`, newUser);
  return res.data;
}

export async function createUser(
  accessToken: string,
  newUser: UserCreateDTO
): Promise<User> {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.post(`${baseUrl}users`, newUser, {
    headers: headers,
  });
  return res.data;
}

export async function updateUser(
  accessToken: string,
  login: string,
  user: UserUpdateDTO
) {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  await axios.put(`${baseUrl}users/${login}`, user, {
    headers: headers,
  });
}

export async function forgotPassword(email: string) {
  await axios.post(`${baseUrl}users/${email}/forgotPassword`);
}

export async function changePassword(passwordChange: PasswordChangeDTO) {
  await axios.post(`${baseUrl}users/resetPassword`, passwordChange);
}

export async function getReadings(
  accessToken: string,
  from: Date | undefined = undefined,
  to: Date | undefined = undefined
): Promise<Reading[]> {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  let readings: Reading[] = [];
  let res = await axios.get(`${baseUrl}readings`, {
    headers: headers,
    params: {
      from: from,
      to: to,
    },
  });

  readings = readings.concat(res.data ?? []);

  let paginationHeader = res.headers["x-pagination"];
  if (!paginationHeader) return readings;

  let paginationObj = JSON.parse(paginationHeader);
  let nextPageId = paginationObj["nextPageIdentifier"];
  if (!nextPageId) return readings;

  while (nextPageId) {
    res = await axios.get(`${baseUrl}readings/pages/${nextPageId}`, {
      headers: headers,
    });
    readings = readings.concat(res.data ?? []);

    paginationHeader = res.headers["x-pagination"];
    if (!paginationHeader) return readings;

    paginationObj = JSON.parse(paginationHeader);
    nextPageId = paginationObj["nextPageIdentifier"];
  }

  return readings;
}

export async function getModels(accessToken: string): Promise<SpectralModel[]> {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await axios.get(`${baseUrl}models`, {
    headers: headers,
  });
  return res.data;
}
