import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import intl from "react-intl-universal";
import User from "spectral-core/models/User";
import Reading from "spectral-core/models/Reading";

const useStyles = makeStyles((theme) => ({
  body: {
    position: "relative",
    marginTop: "0",
  },
  title: {
    borderBottom: " black solid 1px",
    fontSize: "15px",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },
  analistData: {
    marginTop: "5px",
    marginLeft: "5px",
    color: "black",
  },
  fields: {
    marginTop: "5px",
    fontSize: "10px",
    fontFamily: "sans-serif",
    color: "black",
  },

  fieldsParas: {
    fontSize: "10px",
    fontFamily: "sans-serif",
    color: "black",
    display: "table-cell",
    padding: "0px 15px 10px 5px",
    marginTop: "0px",
  },
  comment: {
    marginTop: "0px",
    fontSize: "10px",
    fontFamily: "sans-serif",
    color: "black",
    display: "block",
    width: "350px",
    wordWrap: "break-word",
  },
}));

interface ReportBodyParametersProps {
  selectedReading: Reading;
}

function Parameters(props: ReportBodyParametersProps) {
  const classes = useStyles();
  const lines = [];

  if (props.selectedReading.predictedValues != null) {
    let predictedValues = JSON.parse(props.selectedReading.predictedValues);
    if (predictedValues.length <= 4) {
      let items = [];
      for (let i = 0; i < predictedValues.length; i++) {
        items.push(
          <h3 key={i} className={classes.fieldsParas}>
            {predictedValues[i].ParameterName}: {predictedValues[i].Value}{" "}
          </h3>
        );
      }
      lines.push(items);
    } else {
      let index = 0;
      let column = 4;
      let hasPassad = false;

      while (column <= predictedValues.length) {
        let items = [];

        for (let item = index; item < column; item++) {
          items.push(
            <h3 key={column} className={classes.fieldsParas}>
              {predictedValues[item].ParameterName}:
              {predictedValues[item].Value}{" "}
            </h3>
          );

          index++;
        }

        lines.push(items);

        if (column + 4 > predictedValues.length && !hasPassad) {
          column = predictedValues.length;
          hasPassad = true;
        } else {
          column += 4;
        }
      }
    }
  }

  return (
    <div>
      {lines ? lines.map((item, index) => <div key={index}>{item}</div>) : ""}
    </div>
  );
}

interface ReportBodyProps {
  user: User;
  selectedReading: Reading;
}

export default function Body(props: ReportBodyProps) {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <div className={classes.analistData}>
        <h1 className={classes.title}>{intl.get("report.title")}</h1>
        <h3 className={classes.fields}>
          {intl.get("report.name")} {props.user.name}{" "}
        </h3>
        <h3 className={classes.fields}>
          {intl.get("report.email")} {props.user.email}{" "}
        </h3>
        <h3 className={classes.fields}>
          {intl.get("report.company")} {props.user.company}{" "}
        </h3>
        <h3 className={classes.fields}>
          {intl.get("report.site")} {props.user.site}{" "}
        </h3>
      </div>
      <div className={classes.analistData}>
        <h1 className={classes.title}>{intl.get("report.sampleData")}</h1>
        <h3
          className={classes.fields}
          style={{ float: "right", marginRight: "412px" }}
        >
          {intl.get("report.date")} {props.selectedReading.dateTime}{" "}
        </h3>
        <h3 className={classes.fields}>
          {intl.get("report.analyst")} {props.selectedReading.userName}{" "}
        </h3>
        <h3 className={classes.fields}>
          {intl.get("report.sample")} {props.selectedReading.name}
        </h3>
        <h3 className={classes.fields}>
          {intl.get("report.type")} {props.selectedReading.type}{" "}
        </h3>
        <h3 className={classes.fields}>
          {intl.get("report.temperature")}
          {props.selectedReading.temperature}{" "}
        </h3>
        <h3 className={classes.fields}>
          {intl.get("report.serial")} {props.selectedReading.serialNumber}{" "}
        </h3>
        <h3 className={classes.fields}>
          {intl.get("report.site")}
          {intl.get("report.analyst")}{" "}
          {props.selectedReading.userName
            ? "-"
            : props.selectedReading.userName}{" "}
        </h3>
        <h3 className={classes.fields}>
          {intl.get("report.comment")}
          <span className={classes.comment}>
            {props.selectedReading.comment
              ? "-"
              : props.selectedReading.comment}
          </span>
        </h3>
      </div>
      <div className={classes.analistData}>
        <h1 className={classes.title}>{intl.get("report.parameters")}</h1>
        <Parameters selectedReading={props.selectedReading}></Parameters>
      </div>
    </div>
  );
}
