import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import intl from "react-intl-universal";

import { useRootSelector } from "hooks/reduxHooks";
import { updateUser } from "spectral-core/api";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

//@ts-ignore
const useStyles = makeStyles(styles);

export default function UserProfile() {
  const user = useRootSelector((state) => state.user.currentUser);
  const token = useRootSelector((state) => state.user.currentToken);

  const [name, setName] = useState("");
  const [login, setLogin] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [site, setSite] = useState("");
  const [position, setPosition] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fillProps = () => {
      if (user != null) {
        setName(user.name);
        setLogin(user.login);
        setCompany(user.company);
        setEmail(user.email);
        setSite(user.site);
        setPosition(user.position);
      }
    };

    fillProps();
  }, [user]);

  const classes = useStyles();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;
      case "login":
        setLogin(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "site":
        setSite(event.target.value);
        break;
      case "position":
        setPosition(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;

      default:
        break;
    }
  }

  async function callApi(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (token?.accessToken === undefined) return;
    if (user?.login === undefined) return;

    try {
      await updateUser(token.accessToken, user.login, {
        name: name,
        password: password,
      });

      setOpen(true);
    } catch (error) {
      setOpen(false);
    }
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <form onSubmit={callApi}>
      <GridContainer>
        <div>
          <Snackbar
            //@ts-ignore
            style={{ display: "flex", position: "initial" }}
            place="tc"
            color="success"
            message={intl.get("profile.msg_updateProfile")}
            open={open}
            closeNotification={handleClose}
            close
          />
        </div>
        <GridItem
          //@ts-ignore
          style={{ flexGrow: 1, maxWidth: "100%" }}
          xs={12}
          sm={12}
          md={8}
        >
          {/*@ts-ignore */}
          <Card style={{ height: "100%" }}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                {intl.get("profile.edit")}
              </h4>
              <p className={classes.cardCategoryWhite}>
                {intl.get("profile.update")}
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/*@ts-ignore */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("profile.txt_company")}
                    id="company"
                    formControlProps={{
                      fullWidth: true,
                      fullHeight: true,
                      autoFocus: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: company,
                    }}
                  />
                </GridItem>
                {/*@ts-ignore */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("profile.txt_name")}
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: name,
                      name: "name",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {/*@ts-ignore */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("profile.txt_login")}
                    id="login"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: login,
                      name: "login",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
                {/*@ts-ignore */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("profile.txt_email")}
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: email,
                      name: "email",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {/*@ts-ignore */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("profile.txt_planta")}
                    id="site"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: site,
                      name: "site",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
                {/*@ts-ignore */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("profile.txt_role")}
                    id="position"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: position,
                      name: "position",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
                {/*@ts-ignore */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Senha"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: password,
                      name: "password",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              {/*@ts-ignore */}
              <Button type="submit" color="primary">
                {intl.get("profile.btn_updateProfile")}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
}
