// ##############################
// // // javascript library for creating charts
// #############################
var Chartist = require("chartist");
var Legend = require("chartist-plugin-legend");
// ##############################
// // // variables used to create animation on charts
// #############################
var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 1;

// ##############################
// // // Daily Sales
// #############################

const CreateChart = (x, y) => {
  let x_string = [];

  for (let index = 0; index < x.length; index++) {
    x_string.push(x[index]).toString();
  }

  return {
    data: {
      labels: x_string,
      series: [y],
    },
    options: {
      lineSmooth: Chartist.Interpolation.simple({
        divisor: 2,
      }),
      showPoint: false,
      fullWidth: true,
      low: 0,
      high: 400,
      axisX: {
        labelInterpolationFnc: function (value, index) {
          return index % 50 === 0 ? value : null;
        },
      },
      axisY: {
        type: Chartist.AutoScaleAxis,
      },
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    // for animation
    animation: {
      draw: function (data) {
        if (data.type === "line" || data.type === "area") {
          data.element.animate({
            d: {
              begin: 600,
              dur: 100,
              from: data.path
                .clone()
                .scale(1, 1)
                .translate(0, data.chartRect.height())
                .stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint,
            },
          });
        } else if (data.type === "point") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }
      },
    },
  };
};

const CreateBarChart = (x, y) => {
  let maxY = Math.max.apply(null, y);

  return {
    data: {
      labels: x.map((X) => X.toString()),
      series: [y.map((Y) => parseFloat(Y))],
    },
    options: {
      axisY: {
        type: Chartist.AutoScaleAxis,
        onlyInteger: false,
        ticks: {
          beginAtZero: true,
        },
      },
      showPoint: true,
      fullWidth: true,
      low: 0,
      high: maxY,
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    animation: {
      draw: function (data) {
        if (data.type === "bar") {
          if (!data.group.classes().contains("main")) {
            data.element.attr({ x1: data.x1, x2: data.x2 });
          }
        }
      },
    },
  };
};

const defaultScale = {
  data: {
    labels: ["0", "10", "20", "30", "40", "50", "60"],
    series: [[]],
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0,
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  // for animation
  animation: {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};

// ##############################
// // // Email Subscriptions
// #############################

const emailsSubscriptionChart = {
  data: {
    labels: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
      39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
      75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
      93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123,
      124, 125,
    ],
    series: [
      [
        908.1, 914.29435475806451, 920.488709516129, 926.68306427419361,
        932.8774190322581, 939.0717737903226, 945.26612854838709,
        951.46048330645158, 957.65483806451618, 963.84919282258068,
        970.04354758064517, 976.23790233870966, 982.43225709677426,
        988.62661185483876, 994.82096661290325, 1001.0153213709677,
        1007.2096761290322, 1013.4040308870968, 1019.5983856451613,
        1025.7927404032259, 1031.9870951612904, 1038.1814499193549,
        1044.3758046774194, 1050.5701594354839, 1056.7645141935484,
        1062.9588689516129, 1069.1532237096774, 1075.3475784677421,
        1081.5419332258066, 1087.7362879838711, 1093.9306427419356,
        1100.1249975, 1106.3193522580646, 1112.513707016129, 1118.7080617741935,
        1124.902416532258, 1131.0967712903225, 1137.291126048387,
        1143.4854808064517, 1149.6798355645162, 1155.8741903225807,
        1162.0685450806452, 1168.2628998387097, 1174.4572545967742,
        1180.6516093548387, 1186.8459641129034, 1193.0403188709679,
        1199.2346736290324, 1205.4290283870969, 1211.6233831451614,
        1217.8177379032259, 1224.0120926612904, 1230.2064474193548,
        1236.4008021774193, 1242.5951569354838, 1248.7895116935483,
        1254.9838664516128, 1261.1782212096775, 1267.372575967742,
        1273.5669307258065, 1279.761285483871, 1285.9556402419355, 1292.149995,
        1298.3443497580647, 1304.5387045161292, 1310.7330592741937,
        1316.9274140322582, 1323.1217687903227, 1329.3161235483872,
        1335.5104783064517, 1341.7048330645162, 1347.8991878225806,
        1354.0935425806451, 1360.2878973387096, 1366.4822520967741,
        1372.6766068548388, 1378.8709616129033, 1385.0653163709678,
        1391.2596711290323, 1397.4540258870968, 1403.6483806451613,
        1409.8427354032258, 1416.0370901612905, 1422.231444919355,
        1428.4257996774195, 1434.620154435484, 1440.8145091935485,
        1447.008863951613, 1453.2032187096775, 1459.3975734677419,
        1465.5919282258064, 1471.7862829838709, 1477.9806377419354,
        1484.1749925, 1490.3693472580646, 1496.5637020161291,
        1502.7580567741936, 1508.9524115322581, 1515.1467662903226,
        1521.3411210483873, 1527.5354758064518, 1533.7298305645163,
        1539.9241853225808, 1546.1185400806453, 1552.3128948387098,
        1558.5072495967743, 1564.7016043548388, 1570.8959591129033,
        1577.0903138709677, 1583.2846686290322, 1589.4790233870967,
        1595.6733781451612, 1601.8677329032257, 1608.0620876612904,
        1614.2564424193549, 1620.4507971774194, 1626.6451519354839,
        1632.8395066935486, 1639.0338614516131, 1645.2282162096776,
        1651.4225709677421, 1657.6169257258066, 1663.8112804838711,
        1670.0056352419356, 1676.19999,
      ],
    ],
  },
  options: {
    axisX: {
      showGrid: false,
    },
    low: 0,
    high: 10000,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0,
    },
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          },
        },
      },
    ],
  ],
  animation: {
    draw: function (data) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};

// ##############################
// // // Completed Tasks
// #############################

const completedTasksChart = {
  data: {
    labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
    series: [[230, 750, 450, 300, 280, 240, 200, 190]],
  },
  options: {
    plugins: [Legend()],
    low: 0,
    high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  animation: {
    draw: function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    },
  },
};

module.exports = {
  defaultScale,
  emailsSubscriptionChart,
  completedTasksChart,
  CreateChart,
  CreateBarChart,
};
