import React, { useRef } from "react";
import Box from "@material-ui/core/Box";
//@ts-ignore
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Header from "./Header.js";
import Body from "./Body";
import "../../assets/css/report-style.css";
import intl from "react-intl-universal";
import User from "spectral-core/models/User";
import Reading from "spectral-core/models/Reading";

interface ReportProps {
  user: User;
  selectedReading: Reading;
}

export default function Report(props: ReportProps) {
  const captureRef = useRef<HTMLDivElement>(null);

  function printDocument() {
    if (captureRef.current === null) return;
    html2canvas(captureRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save(
        intl.get("report.reportPathName") + props.selectedReading.name + ".pdf"
      );
    });
  }

  return (
    <div>
      <div ref={captureRef} className="report">
        <Header />
        <Body {...props} />
      </div>
      <Box
        display={{
          xs: "none",
          md: "block",
          xl: "block",
          sm: "none",
          lg: "block",
        }}
      >
        <button onClick={printDocument} className="reportButtom">
          {intl.get("btn.generateReport")}
        </button>
      </Box>
    </div>
  );
}
