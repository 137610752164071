import { useHistory } from "react-router";
import { useRootDispatch, useRootSelector } from "./reduxHooks";
import { useEffect } from "react";
import { logoutAction } from "store/user/user.actions";
import { getReadings } from "spectral-core/api";
import { setReadingsAction } from "store/readings/readings.actions";
import axios from "axios";

export function useGetReadings() {
  const dispatch = useRootDispatch();
  const history = useHistory();

  const accessToken = useRootSelector(
    (state) => state.user.currentToken?.accessToken
  );
  const readings = useRootSelector((state) => state.reading.readings);
  const lastFetchedAtTimestamp = useRootSelector(
    (state) => state.reading.lastFetchedAtTimestamp
  );

  useEffect(() => {
    async function fetchReadingsAndDispatch() {
      try {
        let fromDate: Date | undefined;
        let fetchedAt: number;
        if (lastFetchedAtTimestamp !== undefined) {
          fromDate = new Date();
          fromDate.setTime(lastFetchedAtTimestamp);
          fetchedAt = fromDate.getTime();
        } else {
          fetchedAt = Date.now();
        }
        const apiReadings = await getReadings(accessToken!, fromDate);
        dispatch(
          setReadingsAction({
            readings: apiReadings,
            fetchedAtTimestamp: fetchedAt,
          })
        );
      } catch (err) {
        if (
          axios.isAxiosError(err) &&
          (err.response?.status === 401 || err.response?.status === 403)
        ) {
          dispatch(logoutAction());
          history.push("/");
        }
        console.log(err);
      }
    }

    const nowTimeStamp = Date.now();
    const nowMinusAnHourTimeStamp = nowTimeStamp - 1 * 1000 * 60 * 60;

    if (
      readings === undefined ||
      lastFetchedAtTimestamp === undefined ||
      lastFetchedAtTimestamp < nowMinusAnHourTimeStamp
    ) {
      fetchReadingsAndDispatch();
    }
  }, [dispatch, history, accessToken, readings, lastFetchedAtTimestamp]);
}
