var simpleStatistics = require("simple-statistics");

const methodType = {
  MEAN: "Mean",
  MEDIAN: "Median",
  STDDEVIATION: "StdDeviation",
  MIN: "Min",
  MAX: "Max"
};

Object.freeze(methodType);

const ExecuteStatistics = (modelType, objectArrayToCalculate) => {
  const statisticsModel = {
    type: "",
    data: []
  };

  statisticsModel.type = modelType;

  for (var key in objectArrayToCalculate) {
    if (objectArrayToCalculate.hasOwnProperty(key)) {
      statisticsModel.data.push({
        x: key,
        y: calculate(modelType, objectArrayToCalculate[key])
      });
    }
  }

  return statisticsModel;
};

const calculate = (method, arrayToBeCalculate) => {
  switch (method) {
    case methodType.MEAN:
      return simpleStatistics.mean(arrayToBeCalculate);
    case methodType.MIN:
      return simpleStatistics.min(arrayToBeCalculate);
    case methodType.MAX:
      return simpleStatistics.max(arrayToBeCalculate);
    case methodType.MEDIAN:
      return simpleStatistics.median(arrayToBeCalculate);
    case methodType.STDDEVIATION:
      return simpleStatistics.standardDeviation(arrayToBeCalculate);
    default:
  }
};

module.exports = {
  methodType,
  ExecuteStatistics
};
