import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import intl from "react-intl-universal";
import { useHistory } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";

import { changePassword } from "spectral-core/api";
import { validatePassword } from "utils/user";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

//@ts-ignore
const useStyles = makeStyles(styles);

export default function PasswordChangeView() {
  const classes = useStyles();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [snackbarMsg, setSnackbarMsg] = useState<string>();

  const openSnackbar = snackbarMsg !== undefined;
  const isPasswordValid = validatePassword(password);
  const validationError =
    isPasswordValid || password.length === 0
      ? ""
      : intl.get("passwordChange.invalidPasswordMustIncludeDesc");

  function passwordChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }

  async function callApi(event: React.FormEvent) {
    event.preventDefault();

    const urlParams = new URLSearchParams(window.location.search);
    const resetCode = urlParams.get("code");
    if (resetCode === null) {
      setSnackbarMsg("onrgowregnhin");
      return;
    }

    try {
      await changePassword({ password: password, resetCode: resetCode });
      setSnackbarMsg(intl.get("passwordChange.passwordChangeSucceeded"));
      setTimeout(returnToLoginView, 3000);
    } catch (error) {
      setSnackbarMsg(
        "Failed to change password. Change password request might be invalid or expired"
      );
    }
  }

  function handleClose() {
    setSnackbarMsg(undefined);
  }

  function returnToLoginView() {
    history.push("/login");
  }

  return (
    <form onSubmit={callApi}>
      <div>
        <Snackbar
          //@ts-ignore
          style={{ display: "flex", position: "initial" }}
          place="ec"
          message={snackbarMsg ?? ""}
          open={openSnackbar}
          closeNotification={handleClose}
          close
        />
      </div>

      <GridItem
        //@ts-ignore
        style={{ flexGrow: 1, maxWidth: "100%" }}
        xs={12}
        sm={12}
        md={8}
      >
        {/*@ts-ignore*/}
        <Card style={{ height: "100%" }}>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              {intl.get("passwordChange.title")}
            </h4>
            <p className={classes.cardCategoryWhite}>
              {intl.get("passwordChange.pickNewPassword")}
            </p>
          </CardHeader>
          <CardBody>
            {/*@ts-ignore*/}
            <GridItem align="center">
              <CustomInput
                labelText={intl.get("passwordChange.newPassword")}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: password,
                  onChange: passwordChangeHandler,
                }}
              />
              <span
                style={{
                  color: "red",
                }}
              >
                {validationError}
              </span>
            </GridItem>
          </CardBody>

          <CardFooter>
            {/*@ts-ignore*/}
            <Button
              //@ts-ignore
              type="submit"
              color="primary"
              disabled={isPasswordValid ? false : true}
            >
              {intl.get("passwordChange.change")}
            </Button>
            {/*@ts-ignore*/}
            <Button onClick={returnToLoginView} color="primary">
              {intl.get("passwordChange.cancel")}
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    </form>
  );
}
