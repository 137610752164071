import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useRootDispatch, useRootSelector } from "./reduxHooks";
import { logoutAction } from "store/user/user.actions";

export function useUserLoggedIn() {
  const [loggedIn, setLoggedIn] = useState(false);

  const dispatch = useRootDispatch();
  const userState = useRootSelector((state) => state.user);

  const history = useHistory();

  useEffect(() => {
    if (
      userState.currentUser === undefined ||
      userState.currentToken === undefined ||
      userState.currentToken.expiresAtTimestamp < Date.now()
    ) {
      history.push("/login");
      setLoggedIn(false);
      dispatch(logoutAction());
    } else {
      setLoggedIn(true);
    }
  }, [dispatch, history, userState]);

  return loggedIn;
}
