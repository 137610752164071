import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// core components
import Login from "layouts/Login";
import Admin from "layouts/Admin";
import Register from "layouts/Register";
import { store, persistor } from "store";
import dotenv from "dotenv";
import "assets/css/material-dashboard-react.css";
import ForgotPassword from "views/ForgotPassword/ForgotPassword";
import intl from "react-intl-universal";
import PasswordChangeView from "views/PasswordChange";

dotenv.config();

const hist = createBrowserHistory({
  forceRefresh: false,
});

const locales = {
  "pt-BR": require("./locales/pt-BR.json"),
  "en-US": require("./locales/en-US.json"),
};

const currentLocale = locales[navigator.language as keyof typeof locales]
  ? navigator.language
  : "en-US";

intl.init({
  currentLocale,
  locales,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={hist}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/admin" component={Admin} />
          <Route path="/register" component={Register} />
          <Route path="/forgotpass" component={ForgotPassword} />
          <Route path="/passwordChange" component={PasswordChangeView} />
          <Redirect from="/" to="/login" />
          <Redirect from="/register" to="/register/create-account" />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);
