import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import intl from "react-intl-universal";
import { useHistory } from "react-router-dom";

import validator from "validator";
// import ReCAPTCHA from "react-google-recaptcha";

import { forgotPassword } from "spectral-core/api";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

//@ts-ignore
const useStyles = makeStyles(styles);

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [snackMsg, setSnackMsg] = useState<string>();
  // const [recaptchaValue, setRecaptchaValue] = useState(false);

  const showSnackbar = snackMsg !== undefined;

  const classes = useStyles();

  const history = useHistory();

  // async function onChange(value: React.ChangeEvent<HTMLInputElement>) {
  //   try {
  //     const response = await axios.post(
  //       "https://scir-mobile.azurewebsites.net/api/verifyCaptcha?code=LEYDya/Cnn4pqUX2dOjsoUbZSzshoF0I1xjD9hoyGUMFtyhraUB7TQ==",
  //       {
  //         secret: "6LeWilIeAAAAAI4VhCdVBjgYwo3xnK7QNVBliq0m",
  //         response: value,
  //       }
  //     );

  //     if (response.data.success === true) {
  //       setRecaptchaValue(true);
  //     } else {
  //       setRecaptchaValue(false);
  //     }
  //   } catch (error) {
  //     setopenErrorMsg(true);
  //     setRecaptchaValue(true);
  //   }
  // }

  async function callApi(event: React.FormEvent) {
    event.preventDefault();

    if (email.length === 0) {
      setSnackMsg(intl.get("forgotpass.anEmailIsRequired"));
      return;
    }

    if (!validateForm()) {
      setSnackMsg(intl.get("forgotpass.msg_addError"));
      return;
    }

    try {
      await forgotPassword(email);
      setSnackMsg(intl.get("forgotpass.anEmailHasBeenSent"));
    } catch (error) {
      setSnackMsg(intl.get("forgotpass.errorWhileCommunicatingWithServer"));
    }
  }

  function validateForm() {
    return validator.isEmail(email);
    // && recaptchaValue;
  }

  function returnToLogin() {
    history.push("/login");
    // setRecaptchaValue(false);
  }

  const emailError =
    validator.isEmail(email) || email.length === 0
      ? undefined
      : intl.get("forgotpass.invalid_email");

  return (
    <form onSubmit={callApi}>
      <div>
        <Snackbar
          //@ts-ignore
          style={{ display: "flex", position: "initial" }}
          place="ec"
          message={snackMsg ?? ""}
          open={showSnackbar}
          closeNotification={() => setSnackMsg(undefined)}
          close
        />
      </div>

      <GridItem
        //@ts-ignore
        style={{ flexGrow: 1, maxWidth: "100%" }}
        xs={12}
        sm={12}
        md={8}
      >
        {/*@ts-ignore*/}
        <Card style={{ height: "100%" }}>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              {intl.get("forgotpass.title")}
            </h4>
            <p className={classes.cardCategoryWhite}>
              {intl.get("forgotpass.subtitle")}
            </p>
          </CardHeader>
          <CardBody>
            {/*@ts-ignore*/}
            <GridItem align="center">
              <CustomInput
                labelText={intl.get("forgotpass.txt_email")}
                id="email-address"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: email,
                  name: "email",
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value),
                }}
              />
              {emailError !== undefined && (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {emailError}
                </span>
              )}
            </GridItem>
            ,
            {/* <ReCAPTCHA
              sitekey="6LeWilIeAAAAAGTJG-nuptjvRnJevPlaA0jYOO3O"
              onChange={onChange}
              align="center"
            /> */}
          </CardBody>

          <CardFooter>
            {/*@ts-ignore*/}
            <Button type="submit" color="primary">
              {intl.get("forgotpass.btn_addNew")}
            </Button>
            {/*@ts-ignore*/}
            <Button onClick={returnToLogin} color="primary">
              {intl.get("forgotpass.returnToLogin")}
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    </form>
  );
}
