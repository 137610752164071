import React from "react";
// @material-ui/core components
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MaterialTable from "@material-table/core";

export default function MaterialTableDemo(props) {
  const { headers, tableData, SelectionChange } = props;

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#00bcd4",
      },
      secondary: {
        main: "#00bcd4",
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        columns={headers}
        data={tableData}
        onSelectionChange={(rows) => SelectionChange(rows)}
        options={{
          filtering: true,
          search: false,
          exportButton: false,
          toolbar: false,
          showTitle: false,
          tableLayout: "auto",
          padding: "default",
          selection: true,
          selectionProps: (rowData) => ({
            color: "primary",
          }),
        }}
      />
    </MuiThemeProvider>
  );
}
