import React from "react";
import "components/Map/map-style.css";
import Grid from "@material-ui/core/Grid";
import PricingBox from "components/PricingBox/PricingBox.js";

const Plans = (props) => {
  return (
    <div>
      <div>
        <Grid container>
          <PricingBox />
        </Grid>
      </div>
    </div>
  );
};

export default Plans;
