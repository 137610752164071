/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Computer from "@material-ui/icons/Computer";
import LocalMall from "@material-ui/icons/LocalMall";
import Person from "@material-ui/icons/Person";
import statisticsIc from "@material-ui/icons/BarChart";

// core components/views for Admin layout
import Login from "views/Login/Login";
import DashboardPage from "views/Dashboard/Dashboard";
import ReleasePage from "views/Releases/Releases.js";
import PlansPage from "views/Plans/Plans.js";
import UserProfile from "views/UserProfile/UserProfile";
import UserRegister from "views/UserRegister/UserRegister";
import CreateAccount from "views/CreateAccount/CreateAccount";
import Statistics from "views/Statistics/Statistics";
import intl from "react-intl-universal";
import Coordinates from "views/Coordinates/Coordinates";
import Applications from "views/Applications/Applications";
import ForgotPassword from "views/ForgotPassword/ForgotPassword";
import React from "react";

export interface RouteParams {
  path: string;
  name: string;
  icon: any;
  component: React.FunctionComponent;
  layout: string;
  accessLevel: number;
}

export default function getRoutes(userAccessLevel: number): RouteParams[] {
  const routes = [
    {
      path: "/login",
      name: "Login",
      icon: Dashboard,
      component: Login,
      layout: "/login",
      accessLevel: 1,
    },
    {
      path: "/dashboard",
      name: intl.get("menu.dash"),
      rtlName: "لوحة القيادة",
      icon: Dashboard,
      component: DashboardPage,
      layout: "/admin",
      accessLevel: 1,
    },
    {
      path: "/statistics",
      name: intl.get("menu.statistic"),
      rtlName: "لوحة القيادة",
      icon: statisticsIc,
      component: Statistics,
      layout: "/admin",
      accessLevel: 4,
    },
    {
      path: "/coordinates",
      name: intl.get("coordinate.home"),
      icon: "map",
      component: Coordinates,
      layout: "/admin",
      accessLevel: 4,
    },
    {
      path: "/applications",
      name: intl.get("appmodels.home"),
      icon: "phonelink_ring",
      component: Applications,
      layout: "/admin",
      accessLevel: 4,
    },
    {
      path: "/user",
      name: intl.get("menu.userProfile"),
      rtlName: "ملف تعريفي للمستخدم",
      icon: Person,
      component: UserProfile,
      layout: "/admin",
      accessLevel: 1,
    },
    {
      path: "/register",
      name: intl.get("menu.register"),
      rtlName: "ملف تعريفي للمستخدم",
      icon: "person_add-black",
      component: UserRegister,
      layout: "/admin",
      accessLevel: 4,
    },
    {
      path: "/create-account",
      name: intl.get("menu.createacc"),
      rtlName: "ملف تعريفي للمستخدم",
      icon: "person_add-black",
      component: CreateAccount,
      layout: "/register",
      accessLevel: 1,
    },
    {
      path: "/forgotpass",
      name: intl.get("menu.createacc"),
      rtlName: "ملف تعريفي للمستخدم",
      icon: "person_add-black",
      component: ForgotPassword,
      layout: "/register",
      accessLevel: 1,
    },
    {
      path: "/releases",
      name: intl.get("softwares.softwares"),
      icon: Computer,
      component: ReleasePage,
      layout: "/admin",
      accessLevel: 4,
    },
    {
      path: "/plans",
      name: intl.get("plans.menu_title"),
      icon: LocalMall,
      component: PlansPage,
      layout: "/admin",
      accessLevel: 1,
    },
  ];

  return routes.filter((x) => x.accessLevel <= userAccessLevel);
}
