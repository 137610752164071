import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import "moment/locale/pt-br";
import moment from "moment";
import intl from "react-intl-universal";
import User from "spectral-core/models/User";

const useStyles = makeStyles((theme) => ({
  greeting: {},
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "25px",
    margin: "0",
    textAlign: "center",
  },
  typo: {
    marginBottom: "40px",
    position: "relative",
    textAlign: "center",
  },
}));

interface GreetingProps {
  user: User;
}

export default function Greeting(props: GreetingProps) {
  const [time, setTime] = useState();
  const classes = useStyles();

  useEffect(() => {
    const startTime = () => {
      moment.locale(navigator.language);
      //@ts-ignore
      setTime(moment().format("LL"));
    };

    startTime();
  }, []);

  return (
    //@ts-ignore
    <GridItem xs={12} sm={12} md={12}>
      <div className={classes.typo}>
        <h1 className={classes.note}>
          {intl.get("home.welcome", { nome: props.user.name })}
        </h1>
        <span style={{ paddingTop: "5px" }}>{time}</span>
      </div>
    </GridItem>
  );
}
