const { render } = require("react-dom");

const DashHeaders = [
  { title: "Nome", field: 1, filterPlaceholder: "All" },
  { title: "Planta", field: 2, filterPlaceholder: "All" },
  { title: "Amostra", field: 3, filterPlaceholder: "All" },
  { title: "Tipo", field: 4, filterPlaceholder: "All" },
  { title: "Comentário", field: 5, filterPlaceholder: "All" },
  { title: "Temperatura", field: 8, filterPlaceholder: "All" },
  { title: "Data", field: 6, type: "dateTime", filterPlaceholder: "All", defaultSort: "asc", 
    render: (rowData) => rowData[6] ? new Date(rowData[6]).toLocaleString() : "-" },
  { title: "Serial", field: 7, filterPlaceholder: "All" }
];

const StatHeaders = [
  { title: "Tipo", field: 4, filterPlaceholder: "All" },
  { title: "Nome", field: 1, filterPlaceholder: "All", filtering: true },
  { title: "Planta", field: 2, filterPlaceholder: "All", filtering: true },
  { title: "Amostra", field: 3, filterPlaceholder: "All", filtering: true },
  { title: "Data", field: 6, type: "dateTime", filterPlaceholder: "All", defaultSort: "asc",
    render: (rowData) => rowData[6] ? new Date(rowData[6]).toLocaleString() : "-"
  },
  { title: "Serial", field: 7, filterPlaceholder: "All", filtering: true }
];

const ApplicationsHeaders = [
  { title: "Tipo aplicação", field: 0, filterPlaceholder: "All" },
  { title: "Parâmetro", field: 1, filterPlaceholder: "All", filtering: false },
  { title: "Descrição", field: 6, filterPlaceholder: "All", filtering: false },
  { title: "Autor", field: 5, filterPlaceholder: "All", filtering: false }
];

const convertDataIntoTableData = reads => {
  let dataConverted = reads.map(read => [
    verifyItem(read.id),
    verifyItem(read.userName),
    verifyItem(read.userSite),
    verifyItem(read.name),
    verifyItem(read.type),
    verifyItem(read.comment),
    verifyDateTimeItem(read.dateTime),
    verifyItem(read.serialNumber),
    verifyItem(read.temperature)
  ]);
  return dataConverted;
};

function convertApplicationsDataIntoTableData(applications) {
  let dataConverted = applications.map(application => [
    application.applicationName,
    application.parameterName,
    application.model,
    application.isActive,
    application.isPublic,
    application.author,
    application.description
  ]);

  return dataConverted;
}

const verifyItem = item => {
  if (item === null) {
    return "-";
  } else {
    return item;
  }
};

const verifyDateTimeItem = item => {
  if (item === null) return item;
  const parsedItem = parseDateTime(item)
  if (parsedItem === false) return null;
  return parsedItem;
}

const parseDateTime = dateString => {
  try {
      const [datePart, timePart] = dateString.split(' ');
      const [day, month, year] = datePart.split('/').map(Number);
      const [hours, minutes, seconds] = timePart.split(':').map(Number);
      //console.log(new Date(year, month - 1, day, hours, minutes, seconds).toISOString());
      return new Date(year, month - 1, day, hours, minutes, seconds).toISOString();
  } 
  catch {
    return false;
  }
}


module.exports = {
  DashHeaders,
  StatHeaders,
  ApplicationsHeaders,
  convertDataIntoTableData,
  convertApplicationsDataIntoTableData
};
