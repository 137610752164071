import XLSX from "xlsx";
//@ts-ignore
import _ from "lodash";
import Reading from "spectral-core/models/Reading";
import { ChartData } from "types/chartData";

let header = [];

//@ts-ignore
export const fillHeader = (foundRow, statisticsData) => {
  header = [];

  header.push("ID");
  header.push("UserName");
  header.push("Site");
  header.push("SampleName");
  header.push("DataTime");
  header.push("Type");
  header.push("Comment");
  header.push("S/N");
  header.push("Temperature");
  header.push("Coordinates");

  addWaveLenghtToHeader(JSON.parse(foundRow[0].wavelenght));
  addAllParametersToHeader(statisticsData[4].data);
  return header;
};

export function fillDataInExcel(
  foundRow: Reading[],
  statisticsData: ChartData
) {
  return foundRow.map((reading) =>
    DataTemplate(reading, statisticsData[4].data)
  );
}

function DataTemplate(reading: Reading, parametersHeader: string[]) {
  let data = [];

  data.push(reading.id);
  data.push(reading.userName);
  data.push(reading.userSite);
  data.push(reading.name);
  data.push(reading.dateTime);
  data.push(reading.type);
  data.push(reading.comment);
  data.push(reading.serialNumber);
  data.push(reading.temperature);
  data.push(reading.geoLocation);

  if (reading.signal != null && typeof reading.signal === "string") {
    addSignal(JSON.parse(reading.signal), data);
  }

  if (
    reading.predictedValues != null &&
    typeof reading.predictedValues === "string"
  ) {
    addParameters(JSON.parse(reading.predictedValues), parametersHeader, data);
  }

  return data;
}

//@ts-ignore
const addParameters = (analysisParameter, parameterHeader, data) => {
  if (analysisParameter != null && parameterHeader != null && data != null) {
    //@ts-ignore
    parameterHeader.forEach((parameterName) => {
      if (typeof parameterName === "string") {
        let seachResult = _.find(analysisParameter, {
          ParameterName: parameterName,
        });

        if (seachResult != null && seachResult.Value != null) {
          data.push(seachResult.Value);
        } else {
          data.push(0);
        }
      }
    });
  }
};

//@ts-ignore
const addSignal = (signal, arrayToBeFilled) => {
  if (
    signal != null &&
    Array.isArray(signal) &&
    arrayToBeFilled != null &&
    Array.isArray(arrayToBeFilled)
  ) {
    signal.forEach((value) => {
      if (value != null) {
        arrayToBeFilled.push(value);
      }
    });
  }
};

//@ts-ignore
const addWaveLenghtToHeader = (waveLenght) => {
  //@ts-ignore
  waveLenght.forEach((value) => {
    header.push(value.toString());
  });
};

//@ts-ignore
const addAllParametersToHeader = (AllParameters) => {
  //@ts-ignore
  AllParameters.forEach((paramenter) => {
    header.push(paramenter.toString());
  });
};

//@ts-ignore
export const createStatisticsSheet = (xlsxObject, statisticsData) => {
  for (let index = 0; index < statisticsData.length - 1; index++) {
    xlsxObject.SheetNames.push(statisticsData[index].type);

    let ws_data = [];

    ws_data.push(_.map(statisticsData[index].data, "x"));
    ws_data.push(_.map(statisticsData[index].data, "y"));

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    xlsxObject.Sheets[statisticsData[index].type] = ws;
  }
};
