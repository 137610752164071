import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import intl from "react-intl-universal";
import { useHistory } from "react-router-dom";

import axios from "axios";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import validator from "validator";

//@ts-ignore
// import ReCAPTCHA from "react-google-recaptcha";

import NewCompanyFirstUserCreateDTO from "spectral-core/dtos/NewCompanyFirstUserCreateDTO";
import { createNewCompanyFirstUser } from "spectral-core/api";
import { validatePassword } from "utils/user";
import { AxiosError } from "axios";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

//@ts-ignore
const useStyles = makeStyles(styles);

export default function CreateAccount() {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [site, setSite] = useState("");
  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState("");
  const [position, setPosition] = useState("");
  const [open, setOpen] = useState(false);
  const [openErrorMsg, setopenErrorMsg] = useState(false);
  const [checked, setChecked] = useState(false);

  const [serverErrorMessage, setServerErrorMessage] = useState<
    string | undefined
  >();

  // const [recaptchaValue, setRecaptchaValue] = useState(false);

  const classes = useStyles();

  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "site":
        setSite(
          event.target.value.replace(/[/\\?%*:.!@#$%¨,&^ç~}{;*()|"<>]/g, "")
        );
        break;
      case "position":
        setPosition(event.target.value);
        break;
      case "company":
        if (event.target.value.length < 22) {
          setCompany(
            event.target.value.replace(/[/\\?%*:.!@#$%¨,& ^~}{;*()|"<>]/g, "_")
          );
        } else {
        }

        break;

      default:
        break;
    }
  };

  const validateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
      setEmail(e.target.value);
    } else {
      setEmailError(intl.get("createaccount.invalid_email"));
      setEmail(e.target.value);
    }
  };

  const validatePasswordOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var pass = e.target.value;

    if (validatePassword(pass)) {
      setPassError("");
      setPassword(e.target.value);
    } else {
      setPassError(intl.get("createaccount.invalid_pass"));
      setPassword(e.target.value);
    }
  };

  async function callApi(event: React.FormEvent) {
    event.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      setopenErrorMsg(true);
      setChecked(false);
      return;
    }

    const newCompanyFirstUser: NewCompanyFirstUserCreateDTO = {
      login: email,
      password: password,
      name: name,
      company: company,
      site: site,
      position: position,
      email: email,
    };

    try {
      await createNewCompanyFirstUser(newCompanyFirstUser);

      setOpen(true);
      setChecked(false);
    } catch (error: unknown | AxiosError) {
      setChecked(false);

      if (axios.isAxiosError(error)) {
        const errorContent = error.response?.data;
        if (errorContent.hasOwnProperty("detail")) {
          setServerErrorMessage(errorContent["detail"] as string);
          return;
        }
      }
      setServerErrorMessage(intl.get("createaccount.msg_addServerError"));
    }
  }

  // async function verifyRecaptcha(value: React.ChangeEvent) {
  //   try {
  //     const response = await axios.post(
  //       "https://scir-mobile.azurewebsites.net/api/verifyCaptcha?code=LEYDya/Cnn4pqUX2dOjsoUbZSzshoF0I1xjD9hoyGUMFtyhraUB7TQ==",
  //       {
  //         secret: "6LeWilIeAAAAAI4VhCdVBjgYwo3xnK7QNVBliq0m",
  //         response: value,
  //       }
  //     );

  //     if (response.data.success === true) {
  //       setRecaptchaValue(true);
  //     } else {
  //       setRecaptchaValue(false);
  //     }
  //   } catch (error) {
  //     setopenErrorMsg(true);
  //     setRecaptchaValue(true);
  //   }
  // }

  function validateForm() {
    return (
      name.length > 0 &&
      company.length > 0 &&
      email.length > 0 &&
      emailError.length <= 0 &&
      site.length > 0 &&
      password.length > 0 &&
      passError.length <= 0 &&
      position.length > 0 &&
      checked === true
      // && recaptchaValue === true
    );
  }

  const handleClose = () => {
    setOpen(false);
    setopenErrorMsg(false);

    closeAccountCreation();
  };

  const handleCloseWithoutExitPage = () => {
    setOpen(false);
    setopenErrorMsg(false);
    setServerErrorMessage(undefined);
  };

  const closeAccountCreation = () => {
    history.push("/login");
  };

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <form onSubmit={callApi}>
      <div>
        <Snackbar
          //@ts-ignore
          style={{ display: "flex", position: "initial" }}
          place="ec"
          color="success"
          message={intl.get("createaccount.msg_addNew")}
          open={open}
          closeNotification={handleClose}
          close
        />
        <Snackbar
          //@ts-ignore
          style={{ display: "flex", position: "initial" }}
          place="ec"
          color="fail"
          message={intl.get("createaccount.msg_addError")}
          open={openErrorMsg}
          closeNotification={handleCloseWithoutExitPage}
          close
        />
        <Snackbar
          //@ts-ignore
          style={{ display: "flex", position: "initial" }}
          place="ec"
          color="red"
          message={serverErrorMessage ?? ""}
          open={serverErrorMessage !== undefined}
          closeNotification={() => setServerErrorMessage(undefined)}
          close
        />
      </div>

      <GridItem
        //@ts-ignore
        style={{ flexGrow: 1, maxWidth: "100%" }}
        xs={12}
        sm={12}
        md={8}
      >
        {/*@ts-ignore*/}
        <Card style={{ height: "100%" }}>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              {intl.get("createaccount.title")}
            </h4>
            <p className={classes.cardCategoryWhite}>
              {intl.get("createaccount.subtitle")}
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer>
              {/*@ts-ignore*/}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText={intl.get("createaccount.txt_company")}
                  id="company-disabled"
                  formControlProps={{
                    fullWidth: true,
                    fullHeight: true,
                    autoFocus: true,
                  }}
                  inputProps={{
                    disabled: false,
                    value: company,
                    name: "company",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              {/*@ts-ignore*/}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText={intl.get("createaccount.txt_planta")}
                  id="site"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: site,
                    name: "site",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              {/*@ts-ignore*/}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText={intl.get("createaccount.txt_name")}
                  id="username"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: name,
                    name: "name",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
              {/*@ts-ignore*/}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText={intl.get("createaccount.txt_email")}
                  id="email-address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: email,
                    name: "email",
                    onChange: validateEmail,
                  }}
                />
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {emailError}
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer>
              {/*@ts-ignore*/}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText={intl.get("createaccount.txt_password")}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: password,
                    name: "password",
                    onChange: validatePasswordOnChange,
                  }}
                />
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {passError}
                </span>
              </GridItem>
            </GridContainer>
            <GridContainer>
              {/*@ts-ignore*/}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText={intl.get("createaccount.txt_role")}
                  id="position"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: position,
                    name: "position",
                    onChange: handleChange,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <Typography variant="body2" color="textSecondary" align="center">
            <Link
              target="_blank"
              color="inherit"
              href="https://spectralsolutions.com.br/privacy-policy/"
            >
              {intl.get("createaccount.privacy_terms")}
            </Link>{" "}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeCheckBox}
                    required
                  />
                }
                label={intl.get("createaccount.agree_terms")}
              />
            </FormControl>
          </Typography>
          {/* <ReCAPTCHA
            sitekey="6LeWilIeAAAAAGTJG-nuptjvRnJevPlaA0jYOO3O"
            onChange={verifyRecaptcha}
            align="center"
          /> */}
          ,
          <CardFooter>
            {/*@ts-ignore*/}
            <Button type="submit" color="primary">
              {intl.get("createaccount.btn_addNew")}
            </Button>
            {/*@ts-ignore*/}
            <Button onClick={closeAccountCreation} color="primary">
              {intl.get("createaccount.btn_cancel")}
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    </form>
  );
}
