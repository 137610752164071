import { ExecuteStatistics, methodType } from "./statisticsModels.js";
var _ = require("lodash");

export default class StatisticsHandler {
  constructor(parameters) {
    this.filteredParameters = parameters;
    this.orderNames = [];
    this.allParameters = {};
  }

  orderParameterName() {
    var names = [];

    this.filteredParameters.map(Parameter =>
      names.push(Parameter.ParameterName)
    );
    this.orderNames = _.uniqBy(names);

    this.orderNames.map(
      parameterName => (this.allParameters[parameterName] = [])
    );

    this.filteredParameters.map(Parameter =>
      this.allParameters[Parameter.ParameterName].push(
        Parameter.Value > 0 ? Parameter.Value : 0
      )
    );
  }

  createAnalysis() {
    const statisticalAnalysis = [];
    this.orderParameterName();

    statisticalAnalysis.push(
      ExecuteStatistics(methodType.MIN, this.allParameters)
    );
    statisticalAnalysis.push(
      ExecuteStatistics(methodType.MAX, this.allParameters)
    );
    statisticalAnalysis.push(
      ExecuteStatistics(methodType.MEAN, this.allParameters)
    );
    statisticalAnalysis.push(
      ExecuteStatistics(methodType.STDDEVIATION, this.allParameters)
    );
    statisticalAnalysis.push({ type: "orderedNames", data: this.orderNames });

    return statisticalAnalysis;
  }
}
