import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "components/Card/Card.js";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import intl from "react-intl-universal";
import { loginAction } from "store/user/user.actions";
import Image from "../../assets/img/logo.jpg";
import Logo from "../../assets/img/spectralLogo.jpeg";
import { useRootDispatch, useRootSelector } from "hooks/reduxHooks";
import { loginUser } from "spectral-core/api";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://spectralsolutions.com.br">
        Spectral Solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(4),
    backgroundColor: "#e8e6e6",
  },
  root: {
    background: "black",
  },
  input: {
    color: "white",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    height: "180px",
    width: "100%",
    paddingTop: "5%",
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
  },
  image: {
    marginLeft: "25%",
    marginRight: "25%",
    marginTop: "10%",
    src: `url(${Logo})`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  errorMessage: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "17px",
    marginTop: "30px",
    color: "red",
    textAlign: "center",
  },
}));

export default function Login() {
  const user = useRootSelector((state) => state.user.currentUser);
  const token = useRootSelector((state) => state.user.currentToken);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const history = useHistory();

  const dispatch = useRootDispatch();

  // skip login screen if there's already a user and an unexpired token in local storage
  useEffect(() => {
    if (
      user !== undefined &&
      token !== undefined &&
      token.expiresAtTimestamp > Date.now()
    ) {
      history.push("admin/dashboard");
    }
  }, [history, user, token]);

  useEffect(() => {
    if (error) {
      setLogin("");
      setPassword("");
    }
  }, [error]);

  async function callApi(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoading(true);
    try {
      const userWithToken = await loginUser({
        login: login,
        password: password,
      });
      dispatch(
        loginAction({
          user: userWithToken.user,
          token: {
            accessToken: userWithToken.token.accessToken,
            expiresAtTimestamp: userWithToken.token.expiresAt.getTime(),
          },
        })
      );
      history.push("/admin/dashboard");
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }

  const onChangeLoginHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError(false);
    }
    setLogin(event.target.value);
  };

  const onChangePasswordHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (error) {
      setError(false);
    }
    setPassword(event.target.value);
  };

  const registerForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    try {
      history.push("/register/create-account");
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const forgotForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    try {
      history.push("/forgotpass/");
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Card className={classes.card}>
        <div className={classes.avatar} />
        <CssBaseline />

        <div className={classes.paper}>
          {error ? (
            <h1 className={classes.errorMessage}>
              {intl.get("login.msg_loginFail")}
            </h1>
          ) : null}

          <form className={classes.form} noValidate onSubmit={callApi}>
            <TextField
              // id="standard-required"
              margin="normal"
              required
              fullWidth
              id="login"
              color="primary"
              label={intl.get("login.txt_login")}
              name="login"
              autoComplete="login"
              autoFocus
              value={login}
              onChange={onChangeLoginHandler}
            />
            <TextField
              // id="standard-required"
              margin="normal"
              required
              fullWidth
              name="password"
              label={intl.get("login.txt_password")}
              type="password"
              id="password"
              autoComplete="current-password"
              placeholder={intl.get("login.txt_password")}
              value={password}
              color="primary"
              onChange={onChangePasswordHandler}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              // cursor="progress"
              className={classes.submit}
              disabled={loading}
            >
              {loading && <CircularProgress size={22} />}
              {!loading && intl.get("login.btn_sign")}
            </Button>
          </form>
          <form className={classes.form} noValidate onSubmit={registerForm}>
            <Button type="submit" className="btn btn-link">
              {intl.get("login.btn_createaccount")}
            </Button>
          </form>
          <form className={classes.form} noValidate onSubmit={forgotForm}>
            <Button type="submit" className="btn btn-link">
              {intl.get("login.btn_forgotpass")}
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Card>
    </Container>
  );
}
