import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import axios from "axios";
import intl from "react-intl-universal";
import { useHistory } from "react-router-dom";

import validator from "validator";
import { useRootDispatch, useRootSelector } from "hooks/reduxHooks";
import { createUser } from "spectral-core/api";
import { logoutAction } from "store/user/user.actions";
import { validatePassword } from "utils/user";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

//@ts-ignore
const useStyles = makeStyles(styles);

export default function UserRegister() {
  const loggedInUser = useRootSelector((state) => state.user.currentUser);
  const token = useRootSelector((state) => state.user.currentToken);

  const dispatch = useRootDispatch();

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [site, setSite] = useState("");
  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState("");
  const [position, setPosition] = useState("");
  const [showRegisterSuccessSnack, setShowRegisterSuccessSnack] =
    useState(false);
  const [registerFailedMessage, setRegisterFailedMessage] = useState<
    string | null
  >(null);

  const history = useHistory();

  useEffect(() => {
    const fillProps = () => {
      if (loggedInUser) {
        setCompany(loggedInUser.company);
        setSite(loggedInUser.site);
      }
    };

    fillProps();
  }, [loggedInUser]);

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "site":
        setSite(
          event.target.value.replace(/[/\\?%*:.!@#$%¨,&^ç~}{;*()|"<>]/g, "")
        );
        break;
      case "position":
        setPosition(event.target.value);
        break;

      default:
        break;
    }
  };

  const callApi = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      var isValid = validateForm();
      if (!isValid) {
        setShowRegisterSuccessSnack(false);
        setRegisterFailedMessage(intl.get("createaccount.msg_addError"));
        return;
      }

      if (token === undefined) {
        dispatch(logoutAction());
        history.push("/login");
        return;
      }

      await createUser(token.accessToken, {
        login: email,
        password: password,
        name: name,
        site: site,
        position: position,
        email: email,
        accessLevel: 3,
      });

      setShowRegisterSuccessSnack(true);
      setRegisterFailedMessage(null);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401 || err.response?.status === 403) {
          dispatch(logoutAction());
          history.push("/");
          return;
        }

        const errorContent = err.response?.data;
        if (errorContent.hasOwnProperty("detail")) {
          setShowRegisterSuccessSnack(false);
          setRegisterFailedMessage(errorContent["detail"] as string);
          return;
        }
      }

      setShowRegisterSuccessSnack(false);
      setRegisterFailedMessage(intl.get("createaccount.msg_addError"));
    }
  };

  function validateForm() {
    var isValid = false;

    if (
      name.length > 0 &&
      company.length > 0 &&
      email.length > 0 &&
      emailError.length <= 0 &&
      site.length > 0 &&
      password.length > 0 &&
      passError.length <= 0 &&
      position.length > 0
    ) {
      isValid = true;
    } else {
      isValid = false;
    }

    return isValid;
  }

  const validateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
      setEmail(e.target.value);
    } else {
      setEmailError(intl.get("createaccount.invalid_email"));
      setEmail(e.target.value);
    }
  };

  function validatePasswordOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    var pass = e.target.value;

    if (validatePassword(pass)) {
      setPassError("");
      setPassword(e.target.value);
    } else {
      setPassError(intl.get("createaccount.invalid_pass"));
      setPassword(e.target.value);
    }
  }

  function handleClose(event: React.MouseEventHandler<HTMLButtonElement>) {
    setShowRegisterSuccessSnack(false);
    setRegisterFailedMessage(null);
  }

  return (
    <form onSubmit={callApi}>
      <GridContainer>
        <div>
          <Snackbar
            //@ts-ignore
            style={{ display: "flex", position: "initial" }}
            place="ec"
            color="success"
            message={intl.get("register.msg_addNew")}
            open={showRegisterSuccessSnack}
            closeNotification={handleClose}
            close
          />
          <Snackbar
            //@ts-ignore
            style={{ display: "flex", position: "initial" }}
            place="ec"
            color="fail"
            message={registerFailedMessage ?? ""}
            open={registerFailedMessage !== null}
            closeNotification={handleClose}
            close
          />
        </div>

        <GridItem
          //@ts-ignore
          style={{ flexGrow: 1, maxWidth: "100%" }}
          xs={12}
          sm={12}
          md={8}
        >
          {/*@ts-ignore*/}
          <Card style={{ height: "100%" }}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                {intl.get("register.title")}
              </h4>
              <p className={classes.cardCategoryWhite}>
                {intl.get("register.subtitle")}
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/*@ts-ignore*/}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("register.txt_company")}
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true,
                      fullHeight: true,
                      autoFocus: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: company,
                    }}
                  />
                </GridItem>
                {/*@ts-ignore*/}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("register.txt_planta")}
                    id="site"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: site,
                      name: "site",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
                {/*@ts-ignore*/}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("register.txt_name")}
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: name,
                      name: "name",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
                {/*@ts-ignore*/}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("register.txt_email")}
                    id="email-address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: email,
                      name: "email",
                      onChange: validateEmail,
                    }}
                  />
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {emailError}
                  </span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {/*@ts-ignore*/}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("register.txt_password")}
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: password,
                      name: "password",
                      onChange: validatePasswordOnChange,
                    }}
                  />
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {passError}
                  </span>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {/*@ts-ignore*/}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText={intl.get("register.txt_role")}
                    id="position"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: position,
                      name: "position",
                      onChange: handleChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              {/*@ts-ignore*/}
              <Button type="submit" color="primary">
                {intl.get("register.btn_addNew")}
              </Button>
              {/* <Button color="primary">{intl.get("register.btn_cancel")}</Button> */}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
}
