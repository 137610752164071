import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import getRoutes from "routes";

export default function Register() {
  const switchRoutes = (
    <Switch>
      {getRoutes(1)
        .filter((x) => x.layout === "/register")
        .map((x, idx) => (
          <Route path={x.layout} component={x.component} key={idx} />
        ))}
      <Redirect from="/register" to="/register/create-account" />
    </Switch>
  );

  // initialize and destroy the PerfectScrollbar plugin
  return (
    <div>
      {window.location.pathname !== "/register" ? (
        <div>
          <div>{switchRoutes}</div>
        </div>
      ) : (
        <div>{switchRoutes}</div>
      )}
    </div>
  );
}
