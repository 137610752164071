import SpectralModel from "spectral-core/models/SpectralModel";
import {
  setApplicationsAction,
  setApplicationsLogoutAction,
} from "./applications.actions";
import { createReducer } from "@reduxjs/toolkit";

export interface ApplicationsState {
  applications: SpectralModel[] | undefined;
}

const initialState: ApplicationsState = {
  applications: undefined,
};

const applicationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setApplicationsAction, (state, action) => {
      state.applications = action.payload;
    })
    .addCase(setApplicationsLogoutAction, (state, action) => {
      state.applications = initialState.applications;
    });
});

export default applicationsReducer;
