import React, { useState, useEffect } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Tables/ApplicationsTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  ApplicationsHeaders,
  convertApplicationsDataIntoTableData,
} from "../../utils/table.js";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "components/Map/map-style.css";
import { setApplicationsAction } from "store/applications/applications.actions";
import SpinLoading from "components/Loading/SpinLoading.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import intl from "react-intl-universal";

import { useRootSelector, useRootDispatch } from "hooks/reduxHooks";
import { getModels } from "spectral-core/api";
import { logoutAction } from "store/user/user.actions";
import { useUserLoggedIn } from "hooks/userHooks";

//@ts-ignore
const useStyles = makeStyles(styles);

export default function Applications() {
  const applications = useRootSelector(
    (state) => state.applications.applications
  );
  const accessToken = useRootSelector(
    (state) => state.user.currentToken?.accessToken
  );

  const dispatch = useRootDispatch();

  const classes = useStyles();
  const [columnData, setColumnData] = useState([]);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const isLoggedIn = useUserLoggedIn();

  useEffect(() => {
    async function getModelsFromApi() {
      if (!isLoggedIn) return;

      if (applications === undefined) {
        setLoading(true);
        try {
          const apiModels = await getModels(accessToken!);
          dispatch(setApplicationsAction(apiModels));
        } catch (err) {
          if (
            axios.isAxiosError(err) &&
            (err.response?.status === 401 || err.response?.status === 403)
          ) {
            dispatch(logoutAction());
            history.push("/");
          }
          console.log(err);
        }
        return;
      }

      setColumnData(convertApplicationsDataIntoTableData(applications));
      setLoading(false);
    }

    getModelsFromApi();
  }, [dispatch, history, isLoggedIn, accessToken, applications]);

  function handleClick(event: React.MouseEvent) {
    event.preventDefault();
  }

  return (
    <div>
      <GridContainer>
        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                {intl.get("appmodels.tabbleTitle")}
              </h4>
            </CardHeader>
            <CardBody>
              {loading ? (
                <SpinLoading />
              ) : (
                <Table
                  headers={ApplicationsHeaders}
                  tableData={columnData}
                  tableProps={{
                    onRowClick: handleClick,
                  }}
                ></Table>
              )}
            </CardBody>
          </Card>
        </GridItem>

        {/*@ts-ignore*/}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>{"Partners"}</h4>
            </CardHeader>
            <CardBody>
              <img
                alt="embrapa logo"
                width={200}
                height={150}
                src="https://lirp.cdn-website.com/9ca7363c/dms3rep/multi/opt/1.Selo_Tecnologia-1920w.png"
              ></img>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
