import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import { useHistory } from "react-router-dom";
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

import { setReadingsLogoutAction } from "store/readings/readings.actions";
import { logoutAction } from "store/user/user.actions";
import { useRootDispatch } from "hooks/reduxHooks";

//@ts-ignore
const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const dispatch = useRootDispatch();

  const classes = useStyles();
  const history = useHistory();

  const [openNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState<EventTarget>();

  const handleClickProfile = (event: React.MouseEvent<HTMLElement>) => {
    //@ts-ignore
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(undefined);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    executeOnLogout();
  };

  const executeOnLogout = () => {
    dispatch(logoutAction());
    dispatch(setReadingsLogoutAction());

    localStorage.removeItem("userLocalStorage");

    // history.push("/");
    setOpenProfile(undefined);
  };

  const handleCloseProfile = () => {
    setOpenProfile(undefined);
  };

  const handleProfile = () => {
    history.push("/admin/user");
  };

  return (
    <div>
      <div className={classes.manager}>
        {/*@ts-ignore*/}
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        ></Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          //@ts-ignore
          name="profile"
          id="profile"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          {/*@ts-ignore*/}
          <Person className={classes.icons} />
          {/*@ts-ignore*/}
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Perfil</p>
          </Hidden>
        </Button>

        <Poppers
          //@ts-ignore
          open={openProfile}
          //@ts-ignore
          anchorEl={openProfile}
          transition
          style={{
            position: "relative",
          }}
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              //@ts-ignore
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleProfile}
                      className={classes.dropdownItem}
                    >
                      Perfil
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
