import React from "react";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: "flex",
    height: "2rem",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "solid 1px rgb(128, 128, 128, 0.25)",
  },
  box: {
    width: "20rem",
    height: "30rem",
  },
  imageWrapper: {
    display: "table-cell",
    height: "8rem",
    verticalAlign: "top",
  },
  contentContainer: {
    marginTop: "0.5rem",
    display: "block",
    height: "8rem",
  },
  contentBody: {
    display: "table-row",
    height: "8rem",
  },
  logo: {
    width: "4rem",
    height: "4rem",
  },
}));

const Box2 = (props) => {
  const { title, price, feature } = props;
  const classes = useStyles();
  return (
    <div className={classes.box}>
      <div className="card-header">
        <h4
          className="my-0 font-weight-normal"
          style={{ fontWeight: "bold", "text-align": "center" }}
        >
          {title}
        </h4>
      </div>
      <div className={classes.footerContainer}></div>
      <div className="card-body">
        <h1
          className="card-title pricing-card-title"
          style={{ fontWeight: "bold", "text-align": "center" }}
        >
          ${price} <small className="text-muted"></small>
        </h1>
        <ul className="list-unstyled mt-3 mb-4">
          {feature &&
            feature.map((data, index) => {
              return <li key={index}>{data}</li>;
            })}
        </ul>
        <div className={classes.footerContainer}></div>
        <div
          id="resume"
          style={{ bottom: "0px", height: "68%", textAlign: "center" }}
        >
          <div
            style={{
              margin: "0",
              height: "65%",
              textOverflow: "ellipsis",
              webkitLineClamp: "3",
              webkitBoxOrient: "vertical",
              lineHeight: "1.2rem",
              display: "-webkit-box",
              overflow: "hidden",
            }}
          >
            {}
          </div>
          <div style={{ fontSize: "14px", "text-align": "center" }}>
            <a
              class="Button"
              style={{
                "text-align": "center",
                fontWeight: "bold",
                textDecoration: "none",
                color: "inherit",
              }}
              href="mailto:guilherme@spectralsolutions.com.br;felipe@spectralsolutions.com.br;patricia@spectralsolutions.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <Button type="button" color="primary">
                {props.btnTitle}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Box2;
