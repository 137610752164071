import React, { useState } from "react";
// @material-ui/core components
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MaterialTable from "@material-table/core";

export default function MaterialTableDemo(props) {
  const [selectedRow, setselectedRow] = useState();
  const { headers, tableData, tableProps } = props;
  const { onRowClick } = tableProps;

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#00bcd4",
      },
      secondary: {
        main: "#00bcd4",
      },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        columns={headers}
        data={tableData}
        onRowClick={(event, selectedRow) =>
          onRowClick(event, selectedRow, setselectedRow(selectedRow))
        }
        options={{
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow && selectedRow.tableData.id === rowData.tableData.id
                ? "#EEE"
                : "#FFF",
          }),
          filtering: true,
          search: false,
          exportButton: false,
          toolbar: false,
          tableLayout: "auto",
          padding: "default",
          grouping: true,
        }}
        localization={{}}
      />
    </MuiThemeProvider>
  );
}
