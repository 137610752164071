import Reading from "spectral-core/models/Reading";
import { setReadingsAction, setReadingsLogoutAction } from "./readings.actions";
import { createReducer } from "@reduxjs/toolkit";

export interface ReadingsState {
  readings: Reading[] | undefined;
  lastFetchedAtTimestamp: number | undefined;
}

const initialState: ReadingsState = {
  readings: undefined,
  lastFetchedAtTimestamp: undefined,
};

const readingsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setReadingsAction, (state, action) => {
      state.readings = action.payload.readings;
      state.lastFetchedAtTimestamp = action.payload.fetchedAtTimestamp;
    })
    .addCase(setReadingsLogoutAction, (state, action) => {
      state.readings = initialState.readings;
      state.lastFetchedAtTimestamp = initialState.lastFetchedAtTimestamp;
    });
});

export default readingsReducer;
