import User from "spectral-core/models/User";
import { loginAction, logoutAction, TokenWithTimeStamp } from "./user.actions";
import { createReducer } from "@reduxjs/toolkit";

export interface UserState {
  currentUser: User | undefined;
  currentToken: TokenWithTimeStamp | undefined;
}

const initialState: UserState = {
  currentUser: undefined,
  currentToken: undefined,
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginAction, (state, action) => {
      state.currentUser = action.payload.user;
      state.currentToken = action.payload.token;
    })
    .addCase(logoutAction, (state, _) => {
      state.currentUser = initialState.currentUser;
      state.currentToken = initialState.currentToken;
    });
});

export default userReducer;
